// extracted by mini-css-extract-plugin
export var BenefitCenterText = "benefits-module--BenefitCenterText--c20de";
export var Benefitschild = "benefits-module--Benefitschild--9249f";
export var Tecnologys = "benefits-module--Tecnologys--8d5a7";
export var benefit = "benefits-module--benefit--ced27";
export var benefits = "benefits-module--benefits--8a818";
export var bigImage = "benefits-module--bigImage--4a506";
export var bodyBenefits = "benefits-module--bodyBenefits--76b81";
export var centerBenefitschild = "benefits-module--centerBenefitschild--31c22";
export var contTecnology = "benefits-module--contTecnology--48961";
export var contenerTitle = "benefits-module--contenerTitle--8919e";
export var contentBenefits = "benefits-module--contentBenefits--611d2";
export var tecnology = "benefits-module--tecnology--16702";
export var title = "benefits-module--title--88726";
export var titles = "benefits-module--titles--8ef88";