import React from 'react';
import Button from '../../UI/Button/button';
import * as styles from './postulate.module.scss';
import imgPostulate from '../../../images/Benefits/postulate-ic.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Postulate = () => {

	const {t} = useTranslation();
	
	return (
		<section className={styles.bodyPostulate}>
			<div className={styles.contenerPostulate} >
				<div className={styles.postulate}>
					<img
					data-sal-delay='450'
					data-sal='slide-left'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'
					src={imgPostulate} alt="imagen de postulate"></img>
					<h4
					data-sal-delay='450'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						{t("searchPage.postulate.title")}
						</h4>
					<div className={styles.ContButton}>
						<Button linkTo='/contact' isLink type='black'>
							{t("searchPage.postulate.contact")}
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Postulate ;