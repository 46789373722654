import React from 'react';
import * as styles from './benefits.module.scss';
import imgClothing from '../../../images/Benefits/clothing-ic.svg';
import imgHomework from '../../../images/Benefits/homework-ic.svg';
import imgMedicine from '../../../images/Benefits/medicine-ic.svg';
import imgHappy from '../../../images/Benefits/happy-developers-ic.svg';
import imgBreakfast from '../../../images/Benefits/breakfast-ic.svg';
import imgCapacitation from '../../../images/Benefits/capacitation-ic.svg';
import imgFood from '../../../images/Benefits/food-ic.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const Benefits = () => {

	const {t} = useTranslation();

	return (
		<section className={styles.bodyBenefits}>
			<div className={styles.contenerTitle} >
				<div className={styles.title}>
					<h2 
					data-sal-delay='450'
					data-sal='slide-left'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>{t("searchPage.benefits.title")}</h2>
					<p 
					data-sal-delay='450'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					{t("searchPage.benefits.paragraph")}</p>
				</div>
			</div>
			<div className={styles.contentBenefits}>
				<div className={styles.benefits}>
					<div className={styles.Benefitschild}>
						<div className={styles.benefit}>
							<img src={imgClothing} alt="imagen de vestimenta informal"></img>
							<h4>{t("searchPage.benefits.clothing.0")}<br></br>{t("searchPage.benefits.clothing.0")}</h4>
						</div>
						<div className={styles.benefit}>
							<img src={imgHomework} alt="imagen de dias de homeworking"></img>
							<h4>{t("searchPage.benefits.homework.0")}<br></br>{t("searchPage.benefits.homework.1")}</h4>
						</div>
					</div>
					<div className={styles.centerBenefitschild}>
						<div className={styles.benefit}>
							<img src={imgMedicine} alt="imagen de medicina de primer nivel"></img>
							<h4>{t("searchPage.benefits.medicine.0")}<br></br>{t("searchPage.benefits.medicine.1")}</h4>
						</div>
						<div className={styles.benefit}>
							<img className={styles.bigImage} src={imgHappy} alt="imagen de excelente ambiente laboral"></img>
							<h4 className={styles.BenefitCenterText}>{t("searchPage.benefits.workEnv.0")}<br></br>{t("searchPage.benefits.workEnv.1")}</h4>
						</div>
						<div className={styles.benefit}>
							<img src={imgBreakfast} alt="imagen de desayunos y meriendas"></img>
							<h4>{t("searchPage.benefits.breakfast.0")}<br></br>{t("searchPage.benefits.workEnv.1")}</h4>
						</div>
					</div>
					<div className={styles.Benefitschild}>
						<div className={styles.benefit}>
							<img src={imgCapacitation} alt="imagen de capacitación permanente"></img>
							<h4>{t("searchPage.benefits.training.0")}<br></br>{t("searchPage.benefits.training.1")}</h4>
						</div>
						<div className={styles.benefit}>
							<img src={imgFood}alt="imagen de snacks, bebidas y frutas"></img>
							<h4>{t("searchPage.benefits.food.0")}<br></br>{t("searchPage.benefits.food.1")}</h4>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.Tecnologys} >
				<div className={styles.title}>
					<h2 
					data-sal-delay='450'
					data-sal='slide-left'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>{t("searchPage.benefits.challenges")}</h2>
					<p 
					data-sal-delay='450'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						{t("searchPage.benefits.solveProblems")}
					</p>
					<p 
					data-sal-delay='450'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						{t("searchPage.benefits.technologies")}
					</p>
				</div>
				<div className={styles.contTecnology}>
					<div className={styles.tecnology}>
						<p className={styles.titles}>{t("searchPage.benefits.backend.0")}</p>
						<hr></hr>
						<p>{t("searchPage.benefits.backend.1")}</p>
					</div>
					<div className={styles.tecnology}>
						<p className={styles.titles}>{t("searchPage.benefits.frontend.0")}</p>
						<hr></hr>
						<p>{t("searchPage.benefits.frontend.1")}<br></br>{t("searchPage.benefits.frontend.2")}</p>
					</div>
				</div>
			</div>
		</section>
	);
}


export default Benefits ;