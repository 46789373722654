import React from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import Search from "../components/Search/TitleSearch/titlesearch";
import Benefits from "../components/Search/Benefits/benefits";
import ActiveSearchs from "../components/Search/ActiveSearchs/activesearchs";
import Postulate from "../components/Search/Postulate/postulate";
import { graphql } from "gatsby";

const SearchPage = () => (
  <Layout>
    <SEO title="Codeicus | Search" />
    <Search />
    <Benefits />
    <ActiveSearchs />
    <Postulate />
  </Layout>
)

export default SearchPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;