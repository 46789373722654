import React from 'react';
import * as styles from './activesearchs.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const ActiveSearchs = () => {

	const {t} = useTranslation();

	return (
		<section className={styles.bodyActives}>
			<div className={styles.gradientback}>
				<div className={styles.contenerTitle} >
					<div className={styles.title}>
						<h2 
						data-sal-delay='450'
						data-sal='slide-left'
						data-sal-duration='700'
						data-sal-easing='ease-out-back'>{t("searchPage.activeSearchs.title")}</h2>
						<p 
						data-sal-delay='450'
						data-sal='slide-right'
						data-sal-duration='700'
						data-sal-easing='ease-out-back'>
							{t("searchPage.activeSearchs.paragraph.0")}
							</p>
						<p 
						data-sal-delay='450'
						data-sal='slide-right'
						data-sal-duration='700'
						data-sal-easing='ease-out-back'>
							{t("searchPage.activeSearchs.paragraph.1")}
						</p>
					</div>
				</div>
			</div>
			<div className={styles.containLinksDescription}>           
				<div  
					data-sal-delay='400'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					<a href='/details/analyst' rel="noopener noreferrer">
						<p>{t("searchPage.activeSearchs.functional.0")}</p>
						<span>{t("searchPage.activeSearchs.functional.1")}</span>
					</a>
				</div>
				<div
					data-sal-delay='400'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'> 
					<a href='/details/phpdev' rel="noopener noreferrer">
						<p>{t("searchPage.activeSearchs.php.0")}</p>
						<span>{t("searchPage.activeSearchs.php.1")}</span>
					</a>
				</div>
				<div
					data-sal-delay='400'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					<a href='/details/frontdev' rel="noopener noreferrer">
						<p>{t("searchPage.activeSearchs.frontend.0")}</p>
						<span>{t("searchPage.activeSearchs.frontend.1")}</span>
					</a>
				</div>
				<div
					data-sal-delay='400'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					<a href='/details/javadev' rel="noopener noreferrer">
						<p>{t("searchPage.activeSearchs.java.0")}</p>
						<span>{t("searchPage.activeSearchs.java.1")}</span>
					</a>
				</div>
			</div>
			<div className={styles.question}>
				<p
				data-sal-delay='450'
				data-sal='slide-right'
				data-sal-duration='700'
				data-sal-easing='ease-out-back'>
				{t("searchPage.activeSearchs.other")}</p>
			</div>
		</section>
	);
}

export default ActiveSearchs ;