import React from 'react';
import * as styles from './titlesearch.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Search = () => {

	const {t} = useTranslation();

	return (
		<section className={styles.titlesearchSection}>
			<div className={styles.containCall}>
				<div className={styles.containTitle}>
					<h2
					data-sal-delay='400'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					{t("searchPage.search.title")}</h2>
					<p
					data-sal-delay='450'
					data-sal='slide-left'
				   	data-sal-duration='700'
				   	data-sal-easing='ease-out-back'>{t("searchPage.search.paragraph")}</p>
				</div>
			</div>	
		</section>
	);
};
export default Search;
